





















































































import { computed, defineComponent, onMounted, reactive, ref, watch } from '@vue/composition-api';
import { Pagination, DropDown, SearchBar, CategorySideBar } from '@/components';
import { filterService, lectureService, recommendKeywordService, tagService } from '@/services';
import {
  IFilter,
  ILecture,
  ILectureCategory,
  ILectureOrderByType,
  IRecommendKeyword,
  ITag,
} from '@/models';
import {
  SEARCH_TYPE,
  ORDER_BY_TYPE,
  FILTER_KEY_TYPE,
  normalizeParams,
  LEARNMOA_TYPE,
} from '@/utils';
import { Route } from 'vue-router';

import { LecturesList, LecturesTag } from './components';

export default defineComponent({
  name: 'LecturesIndex',
  components: {
    LecturesList,
    LecturesTag,
    SearchBar,
    Pagination,
    DropDown,
    CategorySideBar,
  },

  setup(_, { root }) {
    const pagination = reactive({
      page: 1,
      size: 20,
    });
    const totalCount = ref(0);

    const data = ref<ILecture[]>([]);

    const recommendKeywords = ref<IRecommendKeyword[]>([]);

    const category = ref('');
    const categories = ref<ILectureCategory>([]);

    const tag = reactive<{ data: ITag[]; lastPage: boolean; page: number }>({
      data: [],
      lastPage: true,
      page: 1,
    });

    const priceTypeFilters = ref<IFilter['filterList']>([
      {
        nm: '유·무료',
        val: '',
      },
    ]);
    const lectureOriginFilters = ref<IFilter['filterList']>([
      {
        nm: '사이트',
        val: '',
      },
    ]);
    const orderTypeFilters = ref<ILectureOrderByType[]>([
      {
        nm: '정렬',
        val: '',
      },
    ]);

    const loading = ref(false);

    const filter = reactive<{
      searchType: SEARCH_TYPE;
      searchKey: string;
      orderType: string;
      priceType: string;
      recommendedKeyword: string;
      lectureOrigin: string;

      tagList: string[];
    }>({
      searchType: SEARCH_TYPE.LectureAll,
      searchKey: '',
      priceType: '',
      orderType: '',
      recommendedKeyword: '',
      lectureOrigin: '',

      tagList: [],
    });

    const selectedTags = ref<string[]>([]);

    const isShowTagList = ref(true);

    const selectedCategoryName = computed(
      () => categories.value.find(item => item.val === category.value)?.nm || '전체',
    );

    async function fetchData() {
      try {
        loading.value = true;

        const params = {
          category: category.value,
          lectureOrderByType: filter.orderType,
          lectureSearchType: filter.searchType,
          lectureSearchKey: filter.searchKey,
          priceType: filter.priceType,
          recommendedKeyword: filter.recommendedKeyword,
          lectureOrigin: filter.lectureOrigin,
          tagList: filter.tagList,
          ...pagination,
        };

        const response = await lectureService.getLectures({
          ...normalizeParams(params),
          category: category.value,
        });

        data.value = [...response.content];

        totalCount.value = response.totalElements;
      } catch {
        // pass
      } finally {
        loading.value = false;
      }
    }

    async function fetchCategory() {
      try {
        const response = await lectureService.getCategories();
        categories.value = response;
      } catch {
        // pass
      }
    }

    async function fetchOrderType() {
      try {
        const response = await lectureService.getOrderByType();
        orderTypeFilters.value = [...orderTypeFilters.value, ...response];
      } catch {
        // pass
      }
    }

    async function fetchTags(isMore = false) {
      if (isMore) {
        tag.page += 1;
      } else {
        tag.page = 1;
        filter.tagList = [];
      }

      try {
        const params = {
          page: tag.page,
          tag: category.value,
          recommendedKeyword: filter.recommendedKeyword,
          priceType: filter.priceType,
          lectureOrigin: filter.lectureOrigin,
        };

        const response = await tagService.getTags({
          ...normalizeParams(params),
          tag: category.value,
        });

        if (isMore) {
          tag.data = [...tag.data, ...response.content];
        } else {
          tag.data = [...response.content];
        }

        tag.lastPage = response.last;

        if (!tag.data.length) {
          isShowTagList.value = false;
        } else {
          isShowTagList.value = true;
        }
      } catch {
        // pass
      }
    }

    async function fetchFilters() {
      try {
        const response = await filterService.getFilters();

        // 우선 가격으로만 지정
        priceTypeFilters.value = [
          ...priceTypeFilters.value,
          ...response.filterValueList.filter(
            (type: IFilter) => type.key === FILTER_KEY_TYPE.PriceType,
          )[0].filterList,
        ];

        lectureOriginFilters.value = [
          ...lectureOriginFilters.value,
          ...response.filterValueList.filter(
            (type: IFilter) => type.key === FILTER_KEY_TYPE.LectureOriginList,
          )[0].filterList,
        ];
      } catch {
        // pass
      }
    }

    async function fetchRecommendKeywords() {
      try {
        if (!category.value) {
          recommendKeywords.value = [];
          return;
        }

        const response = await recommendKeywordService.getRecommendkeywords(category.value);

        recommendKeywords.value = response;
      } catch {
        // pass
      }
    }

    function updateQueryString() {
      root.$router
        .push({
          query: {
            ...normalizeParams({
              ...root.$route.query,

              page: pagination.page,
              size: pagination.size,
              lectureOrderByType: filter.orderType,
              lectureSearchType: filter.searchType,
              lectureSearchKey: filter.searchKey,
              priceType: filter.priceType,
              recommendedKeyword: filter.recommendedKeyword,
              lectureOrigin: filter.lectureOrigin,
            }),
          },
        })
        .catch(() => false);
    }

    function submitSearch() {
      pagination.page = 1;
      updateQueryString();
    }

    function onShowMoreTags() {
      fetchTags(true);
    }

    function onSelectOrderType(value: ORDER_BY_TYPE) {
      filter.orderType = value;

      pagination.page = 1;

      updateQueryString();
    }

    function onSelectPriceType(value: string) {
      filter.priceType = value;

      pagination.page = 1;

      fetchTags(false);

      updateQueryString();
    }

    function onSelectLectureOrigin(value: string) {
      filter.lectureOrigin = value;

      pagination.page = 1;

      fetchTags(false);

      updateQueryString();
    }

    function onSelectTag(name: string) {
      const tagIndex = filter.tagList.indexOf(name);
      if (tagIndex !== -1) {
        filter.tagList.splice(tagIndex, 1);
      } else {
        filter.tagList.push(name);
      }

      pagination.page = 1;
      fetchData();
    }

    function selectRecommendKeyword(value: string) {
      filter.recommendedKeyword = value;

      pagination.page = 1;

      fetchTags(false);

      updateQueryString();
    }

    function onChangePage() {
      window.scrollTo({ top: 0 });

      updateQueryString();
    }

    function setPaginationWithQueryString(query: Route['query']) {
      pagination.page = Number(query.page) || 1;
      pagination.size = Number(query.size) || 20;
    }

    function setCategoryWithQueryString(query: Route['query']) {
      category.value = (query.category || '') as string;
    }

    function setOrderTypeWithQueryString(query: Route['query']) {
      filter.orderType = String(query.lectureOrderByType || '');
    }

    function setSearchFilterWithQueryString(query: Route['query']) {
      filter.searchKey = String(query.lectureSearchKey || '');
    }

    function setPriceTypeWithQueryString(query: Route['query']) {
      filter.priceType = String(query.priceType || '');
    }

    function setRecommendKeywordWithQueryString(query: Route['query']) {
      filter.recommendedKeyword = String(query.recommendedKeyword || '');
    }

    function setLectureOriginWithQueryString(query: Route['query']) {
      filter.lectureOrigin = String(query.lectureOrigin || '');
    }

    watch(
      () => root.$route,
      ({ query }) => {
        if (!(query.category === undefined && category.value === '')) {
          if (query.category !== category.value) {
            // 카테고리 변경될 때 태그 초기화
            // 초기화 안 하면 fetch에서 파라미터로 이전에 담긴 태그들 보냄
            filter.tagList = [];
          }
        }

        setPaginationWithQueryString(query);
        setCategoryWithQueryString(query);
        setOrderTypeWithQueryString(query);
        setSearchFilterWithQueryString(query);
        setPriceTypeWithQueryString(query);
        setRecommendKeywordWithQueryString(query);
        setLectureOriginWithQueryString(query);

        fetchData();
      },
      {
        deep: true,
        immediate: true,
      },
    );

    watch(
      category,
      () => {
        fetchRecommendKeywords();

        fetchTags(false);
      },
      {
        immediate: true,
      },
    );

    onMounted(() => {
      fetchCategory();
      fetchOrderType();
      fetchFilters();
    });

    return {
      LEARNMOA_TYPE,

      pagination,
      totalCount,
      data,
      loading,

      categories,
      tag,
      recommendKeywords,

      priceTypeFilters,
      lectureOriginFilters,
      orderTypeFilters,

      filter,

      selectedTags,

      isShowTagList,

      selectedCategoryName,

      onShowMoreTags,

      onSelectOrderType,
      onSelectPriceType,
      onSelectLectureOrigin,
      submitSearch,
      selectRecommendKeyword,

      onSelectTag,
      onChangePage,
    };
  },
});
