<template>
  <div class="skeleton-loader">
    <vue-skeleton-loader :height="110" :rounded="true"></vue-skeleton-loader>

    <div>
      <vue-skeleton-loader :width="80" :height="10" :rounded="true"></vue-skeleton-loader>
    </div>

    <div>
      <vue-skeleton-loader :width="150" :height="20" :rounded="true"></vue-skeleton-loader>
    </div>

    <div>
      <vue-skeleton-loader :width="100" :height="15" :rounded="true"></vue-skeleton-loader>
    </div>

    <div>
      <vue-skeleton-loader :width="120" :height="10" :rounded="true"></vue-skeleton-loader>
    </div>

    <div>
      <vue-skeleton-loader :width="50" :height="10" :rounded="true"></vue-skeleton-loader>
    </div>
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api';
import VueSkeletonLoader from 'skeleton-loader-vue';

export default defineComponent({
  name: 'SkeletonLoader',
  components: {
    VueSkeletonLoader,
  },
});
</script>

<style lang="scss" scoped>
.skeleton-loader {
  // width: 100%;

  margin: 0;
  padding: 0;

  & > div {
    margin-top: 5px;

    &:first-child {
      width: 100% !important;
    }
  }
}
</style>
