












































import { ILecture } from '@/models';
import { defineComponent, PropType } from '@vue/composition-api';

import { LectureCard, LectureCardSkeleton } from '@/components';

import { CONTENT_TYPE, propSync } from '@/utils';
import { bookMarkSearvice } from '@/services/bookMark';

export default defineComponent({
  name: 'LecturesList',
  props: {
    loading: {
      type: Boolean,
      required: true,
    },
    lectures: {
      type: Array as PropType<ILecture[]>,
      required: true,
    },
  },
  components: {
    LectureCardSkeleton,
    LectureCard,
  },
  setup(props) {
    const syncedLectureList = propSync(props, 'lectures');

    function onOpenLinkClicked(link: string) {
      window.open(link, '_blank');
    }

    async function handleToggleBookMark({
      marked,
      lectureId,
      listIndex,
      bookMarkId,
    }: {
      marked: boolean;
      lectureId: number;
      listIndex: number;
      bookMarkId: number | null;
    }) {
      try {
        if (marked) {
          const newBookMarkId = await bookMarkSearvice.createBookMark({
            contentId: lectureId,
            contentType: CONTENT_TYPE.Lecture,
          });

          syncedLectureList.value[listIndex].bookMarkId = newBookMarkId;
        } else {
          if (!bookMarkId) return;
          await bookMarkSearvice.deleteBookMark(bookMarkId);
        }

        syncedLectureList.value[listIndex].bookMarked = marked;
      } catch {
        // pass
      }
    }

    return {
      syncedLectureList,

      onOpenLinkClicked,

      handleToggleBookMark,
    };
  },
});
