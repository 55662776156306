































import { ITag } from '@/models';
import { defineComponent, PropType, toRefs } from '@vue/composition-api';

export default defineComponent({
  name: 'LecturesTag',
  props: {
    data: {
      type: Array as PropType<ITag[]>,
      required: true,
    },
    lastPage: {
      type: Boolean,
      required: true,
    },
    selectedTags: {
      type: Array as PropType<string[]>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const { selectedTags } = toRefs(props);

    function isSelectedTag(name: string) {
      return selectedTags.value.includes(name);
    }

    function onShowMoreClick() {
      emit('show-more');
    }

    function onSelectTagClick(name: string) {
      emit('select-tag', name);
    }

    return {
      isSelectedTag,
      onShowMoreClick,
      onSelectTagClick,
    };
  },
});
